import React from "react";
import "./AwardPage.css";
import Navbar from "../../components/Navbar/Navbar";
import BrochureAward from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/Brochure-award-2023.pdf";
export default function AwardExcellence() {

    return (
        <>
            <section id="award-inner-section" className="award-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Awards & Scholarships</li>
                                <li>Award for Excellence</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="main-heading text-center pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    Award for Excellence
                                </h1>
                                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    NAB Delhi has been nationally and internationally recognized for its pioneering work
                                    in the interest of the visually impaired people. Among many awards and
                                    felicitations, the two key awards which NAB has received are following
                                </p> */}
                            </div>
                            <p>
                                NAB Delhi Award for Excellence was instituted in 2016 to honour ex-students and trainees who
                                despite their visual disability have achieved excellence in their chosen fields. Every year NAB
                                Delhi honours four persons with blindness or low vision that have performed remarkably in their
                                chosen field and carved out a place of pride in the society. NAB Delhi believes that these awards
                                will inspire the present student and trainees to perform to their potential and aspire to achieve
                                excellence in whatever they do.
                            </p>
                            <p>
                                These awards are also helping in building awareness in the society regarding the capabilities of
                                persons with visual impairments. The NAB Delhi Award for Excellence demonstrates that with
                                the quality education and training provided by NAB Delhi persons with blindness and low vision
                                can excel in any field and become contributing role model citizens for the country.
                            </p>

                        </div>
                        <div className="col-12">
                            <div className="main-heading text-center pb-1">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    Previous Awardees
                                </h2>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2023
                                        </h3>
                                        <a href={BrochureAward} download>
                      See profiles of awardees
                    </a>
                                        <ul className="mt-3 ps-4">
                                            <li>
                                            Pragya Mahajan
                                            </li>
                                            <li>
                                            Prachi Mahajan
                                            </li>
                                            <li>
                                            Lovely Grover
                                            </li>
                                            <li>
                                            Md. Kaish
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2022
                                        </h3>

                                        <ul className="mt-3 ps-4">
                                            <li>
                                                SAURABH PRASAD
                                            </li>
                                            <li>
                                                Cynthia Singh
                                            </li>
                                            <li>
                                                Pinki Rani
                                            </li>
                                            <li>
                                                Anoop Kumar
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2021
                                        </h3>
                                        <ul className="mt-3 ps-4">
                                            <li>
                                                anubhav mitra
                                            </li>
                                            <li>
                                                sucheta narang
                                            </li>
                                            <li>
                                                shubham gupta
                                            </li>
                                            <li>
                                                aparna murthy
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2020
                                        </h3>

                                        <ul className="mt-3 ps-4">
                                            <li>
                                                Avichal Bhatnagar
                                            </li>
                                            <li>
                                                Kameshwari Rao
                                            </li>
                                            <li>
                                                Paavan Malhotra
                                            </li>
                                            <li>
                                                Sana Samad
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2019
                                        </h3>

                                        <ul className="mt-3 ps-4">
                                            <li>
                                                Sonali Jain
                                            </li>
                                            <li>
                                                Goma Rai
                                            </li>
                                            <li>
                                                Rahul Gambhir
                                            </li>
                                            <li>
                                                Rakshit Sethi
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2018
                                        </h3>

                                        <ul className="mt-3 ps-4">
                                            <li>
                                                Namrata Mehta
                                            </li>
                                            <li>
                                                Yogesh Taneja
                                            </li>
                                            <li>
                                                Mukesh Sharma
                                            </li>
                                            <li>
                                                Nupur Jain
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2017
                                        </h3>

                                        <ul className="mt-3 ps-4">
                                            <li>
                                                KRISHAN KUMAR MURUGAN
                                            </li>
                                            <li>
                                                SAVITA SINGH
                                            </li>
                                            <li>
                                                MONICA SHARMA
                                            </li>
                                            <li>
                                                AJAY DHANAK
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4">
                                    <div className="sd-card">
                                        <h3>
                                            2017
                                        </h3>

                                        <ul className="mt-3 ps-4">
                                            <li>
                                                Kartik Sawhney
                                            </li>
                                            <li>
                                                Veena Mehta Verma
                                            </li>
                                            <li>
                                                Madhu Sharma
                                            </li>
                                            <li>
                                                Diwakar Sharma
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-3">
                            The awardees are chosen by a committee setup by the NAB Delhi management. Applications are not
invited for this award.
                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}