import React from "react";
import "./ServiceSection.css";
import "react-multi-carousel/lib/styles.css";
import GetApiCall from "../../../GETAPI";
import { useEffect } from "react";
import { useState } from "react";
import Notiflix from "notiflix";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";



// import required modules
import { Autoplay, Pagination } from "swiper";


// import Ge
export default function ServiceSection() {
  const [allServicesData, setAllServicesData] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetServicesData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if (objdata.fld_status === "Active") {
              activeobjdata.push(objdata);
            }
          });
          setAllServicesData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          setAllServicesData([]);
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      margin: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      showDots: true,
    },
  };
  const responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  return (
    <>
      <section className="service-section pb-0 pt-5 p-xl-5 mt-5">
        <div className="container">
          <div className="row">
            <div className="text-center col-12 main-heading">
              <h2 className="main-title wow animate__animated animate__fadeInUp">
                Services We Provide
              </h2>
              <p className="main-text wow animate__animated animate__fadeInUp">
                The organization lays special emphasis on education and skill
                development.
              </p>
            </div>
            {/* <Carousel 
            autoPlay
            infiniteLoop
            showArrows={false}
            // className="row"
            showStatus={false}
            stopOnHover
            swipeable
            useKeyboardArrows
            slidesToShow={1}
            responsiveOptions={true}
            > */}
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              Keyboard={true}
              className="pt-3 pb-5 px-lg-4 px-xl-0"
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                1424: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Autoplay, Pagination]}
            >
              {allServicesData.map((servicesdata, i) => {
                return (
                  <SwiperSlide>


                    <div className="service-card">
                      <div className="s-card-img">
                        <a
                          href={`/service/${servicesdata.fld_title.replace(
                            / a/g,
                            "-"
                          )}/${servicesdata.fld_id}`}
                          aria-label={servicesdata.fld_title}
                        >
                          {" "}
                          <img
                            src={servicesdata.fld_cover_image}
                            alt={servicesdata.fld_cover_image_alt}
                            className="img-fluid"
                          />
                        </a>
                        <div className="s-content">
                          <h3>
                            <a
                              href={`/service/${servicesdata.fld_title.replace(
                                / /g,
                                "-"
                              )}/${servicesdata.fld_id}`}
                              aria-label={servicesdata.fld_title}
                            >
                              {servicesdata.fld_title}
                            </a>
                          </h3>
                          <p>{servicesdata.fld_short_description}</p>
                        </div>
                      </div>
                    </div>

                    
                    {/* <div className="service-card mb-4">
                  <div className="s-card-img">
                    <a
                      href="#"
                      aria-label="nab delhi"
                    >

                      <img
                        src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                        alt="nabd"
                        className="img-fluid"
                      />
                    </a>
                    <div className="s-content">
                      <h3>
                        <a
                          href="#"
                          aria-label="servicesdata"
                        >
                          servicesdata
                        </a>
                      </h3>
                      <p>servicesdata short description</p>
                    </div>
                  </div>
                </div> */}
                  </SwiperSlide>
                );
              })}
              {/* <SwiperSlide>
                <div className="service-card">
                  <div className="s-card-img">
                    <a
                      href="#"
                      aria-label="nab delhi"
                    >

                      <img
                        src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                        alt="nabd"
                        className="img-fluid"
                      />
                    </a>
                    <div className="s-content">
                      <h3>
                        <a
                          href="#"
                          aria-label="servicesdata"
                        >
                          servicesdata
                        </a>
                      </h3>
                      <p>servicesdata short description</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="s-card-img">
                    <a
                      href="#"
                      aria-label="nab delhi"
                    >

                      <img
                        src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                        alt="nabd"
                        className="img-fluid"
                      />
                    </a>
                    <div className="s-content">
                      <h3>
                        <a
                          href="#"
                          aria-label="servicesdata"
                        >
                          servicesdata
                        </a>
                      </h3>
                      <p>servicesdata short description</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="s-card-img">
                    <a
                      href="#"
                      aria-label="nab delhi"
                    >

                      <img
                        src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                        alt="nabd"
                        className="img-fluid"
                      />
                    </a>
                    <div className="s-content">
                      <h3>
                        <a
                          href="#"
                          aria-label="servicesdata"
                        >
                          servicesdata
                        </a>
                      </h3>
                      <p>servicesdata short description</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="s-card-img">
                    <a
                      href="#"
                      aria-label="nab delhi"
                    >

                      <img
                        src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                        alt="nabd"
                        className="img-fluid"
                      />
                    </a>
                    <div className="s-content">
                      <h3>
                        <a
                          href="#"
                          aria-label="servicesdata"
                        >
                          servicesdata
                        </a>
                      </h3>
                      <p>servicesdata short description</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="s-card-img">
                    <a
                      href="#"
                      aria-label="nab delhi"
                    >

                      <img
                        src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                        alt="nabd"
                        className="img-fluid"
                      />
                    </a>
                    <div className="s-content">
                      <h3>
                        <a
                          href="#"
                          aria-label="servicesdata"
                        >
                          servicesdata
                        </a>
                      </h3>
                      <p>servicesdata short description</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="service-card">
                  <div className="s-card-img">
                    <a
                      href="#"
                      aria-label="nab delhi"
                    >

                      <img
                        src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                        alt="nabd"
                        className="img-fluid"
                      />
                    </a>
                    <div className="s-content">
                      <h3>
                        <a
                          href="#"
                          aria-label="servicesdata"
                        >
                          servicesdata
                        </a>
                      </h3>
                      <p>servicesdata short description</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide> */}
            </Swiper>


            {/* </Carousel> */}

            {/* <MultiCarousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000}
              arrows={false}
            > */}
            {/* <>
              {allServicesData.length != 0 ?
                <div className="me-4 mx-lg-3">
                    <div className="service-card">
                      <div className="s-card-img">
                        <a
                          href={`/service/${allServicesData[0].fld_title.replace(
                            / a/g,
                            "-"
                          )}/${allServicesData[0].fld_id}`}
                          aria-label={allServicesData[0].fld_title}
                        >
                          {" "} 
                          <img
                            src={allServicesData[0].fld_cover_image}
                            alt={allServicesData[0].fld_cover_image_alt}
                            className="img-fluid"
                          />
                        </a>
                        <div className="s-content">
                          <h3>
                            <a
                              href={`/service/${allServicesData[0].fld_title.replace(
                                / /g,
                                "-"
                              )}/${allServicesData[0].fld_id}`}
                              aria-label={allServicesData[0].fld_title}
                            >
                              {allServicesData[0].fld_title}
                            </a>
                          </h3>
                          <p>{allServicesData[0].fld_short_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  :
                  <>
                  </>
                      }
                  </>
                  <>
              {allServicesData.length != 0 ?
                <div className="me-4 mx-lg-3">
                    <div className="service-card">
                      <div className="s-card-img">
                        <a
                          href={`/service/${allServicesData[0].fld_title.replace(
                            / a/g,
                            "-"
                          )}/${allServicesData[0].fld_id}`}
                          aria-label={allServicesData[0].fld_title}
                        >
                          {" "} 
                          <img
                            src={allServicesData[0].fld_cover_image}
                            alt={allServicesData[0].fld_cover_image_alt}
                            className="img-fluid"
                          />
                        </a>
                        <div className="s-content">
                          <h3>
                            <a
                              href={`/service/${allServicesData[0].fld_title.replace(
                                / /g,
                                "-"
                              )}/${allServicesData[0].fld_id}`}
                              aria-label={allServicesData[0].fld_title}
                            >
                              {allServicesData[0].fld_title}
                            </a>
                          </h3>
                          <p>{allServicesData[0].fld_short_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  :
                  <>
                  </>
                      }
                  </>
                  <>
              {allServicesData.length != 0 ?
                <div className="me-4 mx-lg-3">
                    <div className="service-card">
                      <div className="s-card-img">
                        <a
                          href={`/service/${allServicesData[0].fld_title.replace(
                            / a/g,
                            "-"
                          )}/${allServicesData[0].fld_id}`}
                          aria-label={allServicesData[0].fld_title}
                        >
                          {" "} 
                          <img
                            src={allServicesData[0].fld_cover_image}
                            alt={allServicesData[0].fld_cover_image_alt}
                            className="img-fluid"
                          />
                        </a>
                        <div className="s-content">
                          <h3>
                            <a
                              href={`/service/${allServicesData[0].fld_title.replace(
                                / /g,
                                "-"
                              )}/${allServicesData[0].fld_id}`}
                              aria-label={allServicesData[0].fld_title}
                            >
                              {allServicesData[0].fld_title}
                            </a>
                          </h3>
                          <p>{allServicesData[0].fld_short_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  :
                  <>
                  </>
                      }
                  </>
                  <>
              {allServicesData.length != 0 ?
                <div className="me-4 mx-lg-3">
                    <div className="service-card">
                      <div className="s-card-img">
                        <a
                          href={`/service/${allServicesData[0].fld_title.replace(
                            / a/g,
                            "-"
                          )}/${allServicesData[0].fld_id}`}
                          aria-label={allServicesData[0].fld_title}
                        >
                          {" "} 
                          <img
                            src={allServicesData[0].fld_cover_image}
                            alt={allServicesData[0].fld_cover_image_alt}
                            className="img-fluid"
                          />
                        </a>
                        <div className="s-content">
                          <h3>
                            <a
                              href={`/service/${allServicesData[0].fld_title.replace(
                                / /g,
                                "-"
                              )}/${allServicesData[0].fld_id}`}
                              aria-label={allServicesData[0].fld_title}
                            >
                              {allServicesData[0].fld_title}
                            </a>
                          </h3>
                          <p>{allServicesData[0].fld_short_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  :
                  <>
                  </>
                      }
                  </>
                  <>
              {allServicesData.length != 0 ?
                <div className="me-4 mx-lg-3">
                    <div className="service-card">
                      <div className="s-card-img">
                        <a
                          href={`/service/${allServicesData[0].fld_title.replace(
                            / a/g,
                            "-"
                          )}/${allServicesData[0].fld_id}`}
                          aria-label={allServicesData[0].fld_title}
                        >
                          {" "} 
                          <img
                            src={allServicesData[0].fld_cover_image}
                            alt={allServicesData[0].fld_cover_image_alt}
                            className="img-fluid"
                          />
                        </a>
                        <div className="s-content">
                          <h3>
                            <a
                              href={`/service/${allServicesData[0].fld_title.replace(
                                / /g,
                                "-"
                              )}/${allServicesData[0].fld_id}`}
                              aria-label={allServicesData[0].fld_title}
                            >
                              {allServicesData[0].fld_title}
                            </a>
                          </h3>
                          <p>{allServicesData[0].fld_short_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
            
                  :
                  <>
                  </>
                      }
                  </> */}

            {/* 
<div className="me-4 mx-lg-3">
                    <div className="service-card">
                      <div className="s-card-img">
                        <a
                          href="#"
                          aria-label="nab delhi"
                        >
                          
                          <img
                            src="http://demos.codexcoder.com/labartisan/html/covid-19/assets/images/team/04.jpg"
                            alt="nabd"
                            className="img-fluid"
                          />
                        </a>
                        <div className="s-content">
                          <h3>
                            <a
                              href="#"
                              aria-label="servicesdata"
                            >
                              servicesdata
                            </a>
                          </h3>
                          <p>servicesdata short description</p>
                        </div>
                      </div>
                    </div>
            </div>*/}
            {/* </MultiCarousel> */}


          </div>
        </div>
      </section>
    </>
  );
}
