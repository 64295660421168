import Notiflix from "notiflix";
//var urlString = "http://localhost:8039/nabdapi/";
var urlString = "https://cms.nabdelhi.in/nabdapi/";

// var schema;
// if (localStorage.getItem("ClientDetails") != null) {
//   schema = JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_schema;
// }
var PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
        schema: 'nabdelhi_db',
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (response.status === 401) {
          Notiflix.Notify.failure(
            "You are not authorized to access this page. Please log in and try again."
          );
          window.location.href = "/";
        } else {
          return response;
        }
      })
      .catch((error) => {
        return error;
      });
  },
};
export default PostApiCall;
