import React from "react";
import Navbar from "../Navbar/Navbar";
import "./DonateNow.css";
export default function SupportUs() {

    return (
        <>
            <section id="donate-inner-section" className="donate-inner-section inner-banner">
                <Navbar />
                <div className="page-title-area">
                    <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
                        <div className="page-title-content">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li>Support Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <div className="main-heading pb-1">
                                <h1 className="main-title wow animate__animated animate__fadeInUp">
                                    <a href="https://razorpay.me/@nabdelhi" target="_blank">
                                        Please Support Us
                                    </a>
                                </h1>
                                <p className="main-text wow animate__animated animate__fadeInUp">
                                    Please donate any amount or consider the options listed below. You can donate now using Net Banking, any Debit or Credit card and popular wallets. Bank transfer information and more donation options are given below. All donations are eligible for 50% Income Tax concession under Section 80G.
                                </p>

                            </div>

                        </div>


                        <div className="col-lg-6">
                            <div className="donation-card">
                                <div className="main-heading main-heading-left pb-1">
                                    <h2 className="main-title wow animate__animated animate__fadeInUp">
                                        <a href="https://razorpay.me/@nabdelhi" target="_blank">Donate Online Now</a>
                                    </h2>
                                </div>
                                <div>
                                    <p>
                                        Your generous contributions can help us continue our mission of empowering and supporting the visually impaired community in Delhi.
                                    </p>
                                    <p>
                                        At NAB Delhi, we have been providing services and support to individuals with visual impairments for over 44 years. Our services include education, training, employment, and rehabilitation programmes. Our goal is to help visually impaired individuals live independent and fulfilling lives.
                                    </p>
                                    <p>
                                        Donation can be made by cash OR cheque / draft / bank transfer in favour of “National Association for the Blind”.
                                    </p>
                                    {/* <p className="mb-1">PayTm: 9212319671</p> */}
                                    <p>UPI ID: <b>9711843505m@pnb</b></p>
                                </div>


                                <div>
                                    <a href="https://razorpay.me/@nabdelhi" target="_blank" className="default-btn d-inline-block mt-3">Donate Online Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-md-5 mb-lg-0">
                            <div className="donation-card">
                                <div className="main-heading main-heading-left pb-1">
                                    <h2 className="main-title wow animate__animated animate__fadeInUp">
                                        <a href="https://secure.givelively.org/donate/angels-for-angels/national-association-for-the-blind-delhi" target="_blank">Donating from outside India (FCRA)</a>
                                    </h2>
                                </div>
                                <div>
                                    <p>
                                        We are eligible to receive donations from all over the world. Such donations have to be routed to the designated FCRA bank account. Please use the online donation link given below or contact us if you need our FCRA (foreign donation) Bank account details.
                                    </p>
                                    <p>
                                        Donations made by USA citizens are exempt from Federal income tax under section 501(c) (3) of the Internal Revenue Code of USA through Angels for Angels (Fiscal Sponsor)
                                    </p>
                                    <p>
                                        NAB Delhi is in constant need of your help. To enable us to support our deserving and talented group of blind, Deaf-Blind and Multi-Disabled children and youth please consider donating in cash or kind. Some of the options for supporting us are listed below. Besides this you may contact us to discuss other options.
                                    </p>
                                </div>
                                <div>
                                    <a href="https://secure.givelively.org/donate/angels-for-angels/national-association-for-the-blind-delhi" target="_blank" className="default-btn d-inline-block mt-3">Donation Gateway for Foreigners</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4 mt-lg-5 mb-5">
                            <div className="main-heading pb-1 text-center">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    OR
                                </h2>
                            </div>
                            <p>
                                Donation can be made by cash OR
                                cheque / draft / bank transfer in favour of “National Association for the Blind”.
                                You can send your small contributions via<b> UPI to 9711843505m@pnb.</b>

                            </p>
                            <p>
                                All donations are eligible for 50% Income Tax concession under Section 80G.
                            </p>
                            <p>
                                <b>Donating from outside India? Please use the PayPal button given above.

                                </b>
                            </p>
                            <p>
                                Donors who do not have Indian passport need to transfer funds to our FCRA bank account or use the PayPal button given above.
                            </p>
                            <p>
                                Please contact us if you want our FCRA (foreign donation) Bank account details.
                            </p>
                            <p>
                                FCRA Registration number: 231650640
                            </p>
                            <p>
                                Contact us for receipts and income tax exemption certificates and more ways to contribute.
                            </p>
                            <p>
                                Phone: <a href="tel:+918826260010"> +91 88262 60010</a>,<a href="tel:+01126175886"> +91 11 26175886</a>.
                            </p>
                            <p>
                                Email: <a href="mailto:info@nabdelhi.in">info@nabdelhi.in.
                                </a>
                            </p>
                        </div>
                        <div className="col-12 text-center">
                            <div className="main-heading pb-1">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    Donation Options
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-md-5 mb-lg-0">
                            <div className="donation-card sponsor-card">
                                <table class="content-table content-table-2">
                                    <thead>
                                        <tr className="border-bottom border-light text-center">
                                            <th colSpan={3}>
                                                <h3>
                                                    Sponsor Food for the Blind
                                                </h3>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th colSpan={3}>
                                                Please consider sponsoring food for the blind children on your birthday, anniversary or whenever you desire.
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Simple Breakfast for 200 blind children at</td>
                                            <td>INR 3,000</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>Special Breakfast for 200 blind children</td>
                                            <td>INR 3,500</td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>Lunch / Dinner for 200 blind children</td>
                                            <td>INR 5,100</td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>Special Lunch / Dinner for 200 blind children</td>
                                            <td>INR 7,000</td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>Snacks for hostel students at 4 pm </td>
                                            <td>INR 4,000</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="donation-card donation-card-2 sponsor-card">
                                {/* <div className="main-heading main-heading-left pb-1">
                                    <h2 className="main-title wow animate__animated animate__fadeInUp">
                                        Sponsor breakfast, lunch or dinner:
                                    </h2>
                                </div> */}
                                <div>

                                    <table class="content-table content-table-2">
                                        <thead>
                                            <tr className="border-bottom border-light text-center">
                                                <th colSpan={3}>
                                                    <h3>
                                                        Sponsor a blind child
                                                    </h3>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    S.No.
                                                </th>
                                                <th>
                                                    Sponsorship
                                                </th>
                                                <th>
                                                    Yearly Donation
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>School going blind child using Hostel & school transport facilities</td>
                                                <td>Rs. 1,60,000</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>School going blind child (day scholar, not using hostel and transport facility) </td>
                                                <td>Rs. 65,000</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Preparatory/nursery student in hostel (blind child aged 4 to 7 years)</td>
                                                <td>Rs. 1,33,000</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Preparatory/nursery student (blind child aged 4 to 7 years) </td>
                                                <td>Rs. 58,000</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Child with multiple disabilities </td>
                                                <td>Rs. 77,000</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Computer training for the blind </td>
                                                <td>Rs. 25,000</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Braille/Talking Books for a Child</td>
                                                <td>Rs. 10,000</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Laptop for a School Going Child</td>
                                                <td>Rs. 35,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <p>
                                Contact us for receipts and income tax exemption certificates and more ways to contribute.
                            </p>
                            <p>
                                Phone: <a href="tel:+918826260010"> +91 8826260010</a>,<a href="tel:+01126175886"> +91 11 26175886</a>
                            </p>
                            <p>
                                Email: <a href="mailto:info@nabdelhi.in">info@nabdelhi.in.
                                </a>
                            </p>
                        </div>
                        <div className="col-12 mt-4 mt-lg-5">
                            <div className="main-heading text-center pb-1 mb-3">
                                <h2 className="main-title wow animate__animated animate__fadeInUp">
                                    Bank transfer information for donation from India

                                </h2>
                            </div>
                            <div className="bank-table w-100">
                                <table class="content-table content-table-2 bank-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                Account Name
                                            </th>
                                            <th>Bank A/c No</th>
                                            <th>Bank Name</th>
                                            <th>Branch</th>
                                            <th>Acc. Type</th>
                                            <th>IFSC/RTFS/NEFT Code</th>
                                            <th>PAN No.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>M/s NAB</td>
                                            <td>0162000100227946</td>
                                            <td>Punjab National Bank</td>
                                            <td>Sec. 4 Market, RK Puram, New Delhi 110022</td>
                                            <td>Savings</td>
                                            <td>PUNB0016200</td>
                                            <td>AAATT0506C</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <p>
                                Account Name: M/s NAB <br />
                                Bank A/c No. 0162000100227946<br />
                                Punjab National Bank, Sec. 4 Market, RK Puram, New Delhi 110022.<br />
                                Acc. Type: Savings<br />
                                IFSC/RTFS/NEFT Code: PUNB0016200<br />
                                PAN No.: AAATT0506C


                            </p> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}