import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Footer from "../src/components/Footer/Footer";
import ContactUs from "../src/pages/ContactUs";
import Home from "../src/pages/Homepage";
import "./App.css";
// import Toggle from './components/DarkMode/ToggleButton';
import AboutOrganisation from "./components/AboutPage/AboutOrganisation/AboutOrganisation";
import Downloads from "./components/AboutPage/Downloads/Downloads";
import GoverningCouncil from "./components/AboutPage/GoverningCouncil/GoverningCouncil";
import HistoryAchievements from "./components/AboutPage/HistoryAchievements/HistoryAchievements";
import OfficeBearers from "./components/AboutPage/OfficeBearers/OfficeBearers";
import AlumniPage from "./components/AlumniPage/AlumniPage";
import AwardPage from "./components/AwardPage/AwardPage";
import TopBtn from "./components/BackToTop/top";
import CsrPage from "./components/CsrPage/CsrPage";
import Disclaimer from "./components/DisclaimerPage/Disclaimer";
import DonateBtn from "./components/DonateBtn/DonateBtn";
import EventDetail from "./components/EventPage/EventDetail/EventDetail";
import EventPage from "./components/EventPage/EventPage";
import NewsBlogPage from "./components/NewsBlogPage/NewsBlogPage";
import NewsBlogDetail from "./components/NewsBlogPage/NewsBlogsDetail/NewsBlogsDetail";
import Newsletter from "./components/NewsletterPage/Newsletter";
import Partners from "./components/PartnersPage/Partners";
import RefundPolicyPage from "./components/RefundPolicyPage/RefundPolicyPage";
import ServicePage from "./components/ServicePage/ServicePage";
import StoriesPage from "./components/StoriesPage/StoriesPage";
import TermsConditionsPage from "./components/TermsConditionsPage/TermsConditionsPage";
import VolunteerPage from "./components/VolunteerPage/VolunteerPage";
import PhotoGallery from "./pages/PhotoGallery";
import VideoGalleryPage from "./pages/VideoGalleryPage";
// import Navbar from "./components/Navbar/Navbar";
import NotFoundPage from "./components/404Page/404Page";
import AwardExcellence from "./components/AwardPage/AwardExcellence";
import LakshmiIyerScholarship from "./components/AwardPage/LakshmiIyerScholarship";
import SanjayTandon from "./components/AwardPage/SanjayTandon";
import SarojiniTrilok from "./components/AwardPage/SarojiniTrilok";
import MadhuSharma from "./components/AwardPage/SudhaVerma";
import DonarsPage from "./components/donars-page/donars";
import DonateNow from "./components/DonateNowPage/DonateNow";
import SupportUs from "./components/DonateNowPage/SupportUs";
import EventGalleryPage from "./components/EventPage/EventsGalleryPage";
import ProjectDetail from "./components/ProjectPage/ProjectDetail";
import ProjectGalleryPage from "./components/ProjectPage/ProjectGalleryPage";
import ProjectOngoingPage from "./components/ProjectPage/ProjectOngoingPage";
import ServiceGallery from "./components/ServicePage/ServiceGalleryPage";
import AlbumGalleryPage from "./pages/AlbumGalleryPage";
import BeyondPage from "./components/beyondpage/beyond-experience";
// import DivyangSamaan from "./components/AwardPage/DivyangSamaan";

function App() {
  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <DonateBtn />
      {/* <Toggle /> */}
      <TopBtn />
      <Routes>
        <Route path='/index.php' element={<Navigate to="/" />} />
        <Route path='/madhu-sharma-young-achiever-award' element={<Navigate to="/sudha-verma-young-achiever-award" />} />
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/about-organisation"
          element={<AboutOrganisation />}
        />
        <Route exact path="/history-achievements" element={<HistoryAchievements />} />
        <Route exact path="/our-team" element={<OfficeBearers />} />
        <Route exact path="/governing-council" element={<GoverningCouncil />} />
        <Route exact path="/downloads" element={<Downloads />} />
        <Route
          exact
          path="/service/:servicename/:id"
          element={<ServicePage />}
        />
        <Route
          exact
          path="/services-gallery/:serviceid"
          element={<ServiceGallery />}
        />
        <Route
          exact
          path="/projects-gallery/:projectid"
          element={<ProjectGalleryPage />}
        />
        <Route exact path="/ongoing-projects" element={<ProjectOngoingPage />} />
        {/* <Route exact path="/completed-projects" element={<ProjectCompletedPage />} />  */}
        <Route
          exact
          path="/completed-projects/:projectname/:id"
          element={<ProjectDetail />}
        />
        <Route
          exact
          path="/ongoing-projects/:projectname/:id"
          element={<ProjectDetail />}
        />
        <Route
          exact
          path="/project-gallery/:projectid"
          element={<ProjectGalleryPage />}
        />
        <Route exact path="/awards-scholarships" element={<AwardPage />} />
        <Route exact path="/sarojini-trilok-nath-national-award" element={<SarojiniTrilok />} />
        <Route exact path="/award-for-excellence" element={<AwardExcellence />} />
        <Route exact path="/sudha-verma-young-achiever-award" element={<MadhuSharma />} />
        <Route exact path="/sanjay-tandon-memorial-scholarship" element={<SanjayTandon />} />
        <Route exact path="/lakshmi-iyer-scholarship" element={<LakshmiIyerScholarship />} />
        {/* <Route exact path="/divyangjan-swabhimaan-sammaan-award" element={<DivyangSamaan />} /> */}
        <Route exact path="/impact-stories" element={<StoriesPage />} />
        <Route exact path="/events" element={<EventPage />} />
        <Route
          exact
          path="/event-detail/:eventname/:id"
          element={<EventDetail />}
        />
        <Route
          exact
          path="/event-gallery/:eventid"
          element={<EventGalleryPage />}
        />

        <Route exact path="/news-blogs" element={<NewsBlogPage />} />
        <Route exact path="/newsletter" element={<Newsletter />} />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        <Route exact path="/be-a-volunteer" element={<VolunteerPage />} />
        <Route exact path="/be-a-csr" element={<CsrPage />} />
        <Route
          exact
          path="/news-blog-detail/:newsblogtitle/:id"
          element={<NewsBlogDetail />}
        />
        <Route
          exact
          path="/terms-conditions"
          element={<TermsConditionsPage />}
        />
        <Route exact path="/refund-policy" element={<RefundPolicyPage />} />
        <Route exact path="/alumni" element={<AlumniPage />} />
        <Route exact path="/blog" element={<ContactUs />} />
        <Route exact path="/partners" element={<Partners />} />
        <Route exact path="/donate" element={<DonateNow />} />
        <Route exact path="/support-us" element={<SupportUs />} />
        <Route exact path="/photo-gallery" element={<PhotoGallery />} />
        <Route exact path="/donors" element={<DonarsPage />} />
        <Route exact path="/beyond-eyes-experience" element={<BeyondPage />} />
        <Route
          exact
          path="/album-gallery/:albumid"
          element={<AlbumGalleryPage />}
        />
        <Route exact path="/video-gallery" element={<VideoGalleryPage />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
