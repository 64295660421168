import React from "react";
import "./AwardPage.css";
import Navbar from "../../components/Navbar/Navbar";
import Individualdoc from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/NAB-Sarojini-Trilok-Nath-Best-Individual-Award.docx";
import Institutiondoc from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/NAB-Sarojini-Trilok-Nath-Best-Institution.docx";
import RoleModeldoc from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/NAB-Sarojini-Trilok-Nath-Best-Role-Model.docx";
import CSRdoc from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/NAB-Sarojini-Trilok-Nath-CSR-Impact-Award.docx";
import AwardSchemepdf from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/NAB-Sarojini-Trilok-Nath-National-Award-Scheme.pdf";
import Awardees2021pdf from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/NAB-Delhi-Awards-2021.pdf";
import Awardees2022pdf from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/NAB-Delhi-Awardees-2022.pdf";
import BrochureAward from "../../assets/Application-Forms-NAB-Sarojini-Trilok-Nath-National-Award/Brochure-award-2023.pdf";
import { BsFiletypeDocx } from "react-icons/bs";
import { BsFileEarmarkPdf } from "react-icons/bs";
export default function SarojiniTrilok() {
  return (
    <>
      <section
        id="award-inner-section"
        className="award-inner-section inner-banner">
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Awards & Scholarships</li>
                <li>Sarojini Trilok Nath National Award</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            <div className="col-12 mb-4">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  NAB-Sarojini Trilok Nath National Award
                </h1>
                {/* <p className="main-text wow animate__animated animate__fadeInUp">
                                    NAB Delhi has been nationally and internationally recognized for its pioneering work
                                    in the interest of the visually impaired people. Among many awards and
                                    felicitations, the two key awards which NAB has received are following
                                </p> */}
              </div>
              <p>
                This award was instituted to perpetuate the memory of late Smt.
                Sarojini &amp; Late Shri. Trilok Nath, past President of
                NAB-Delhi who have rendered great support for the cause of the
                blind.
              </p>
              <p>
                Over the years this award has emerged as one of the prominent
                awards given to individuals and institutions that have done
                remarkable work for persons with disabilities, particularly the
                visually impaired. Every year in October applications are
                invited for prestigious NATIONAL LEVEL NAB-SAROJINI TRILOK NATH
                AWARDS from individuals, institutions and CSR Initiatives to
                recognize outstanding work in education, empowerment or
                rehabilitation of visually impaired.
              </p>
              <h2 className="main-heading main-heading-left main-title wow animate__animated animate__fadeInUp mt-5 mb-3">
                Download Award Scheme information and Application Forms
              </h2>
              <ul className="sarojini-downloads">
                <li>
                  <a href={Individualdoc} download>
                    <span> NAB Sarojini Trilok Nath Best Individual Award</span>
                    <BsFiletypeDocx />
                  </a>
                </li>
                <li>
                  <a href={Institutiondoc} download>
                    NAB Sarojini Trilok Nath Best Institution
                    <BsFiletypeDocx />
                  </a>
                </li>
                <li>
                  <a href={RoleModeldoc} download>
                    NAB Sarojini Trilok Nath Best Role Model
                    <BsFiletypeDocx />
                  </a>
                </li>
                <li>
                  <a href={CSRdoc} download>
                    NAB Sarojini Trilok Nath CSR Impact Award
                    <BsFiletypeDocx />
                  </a>
                </li>
                <li>
                  <a href={AwardSchemepdf} download>
                    NAB Sarojini Trilok Nath National Award Scheme
                    <BsFileEarmarkPdf />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <div className="main-heading text-center pb-1">
                <h2 className="main-title wow animate__animated animate__fadeInUp">
                  Previous Awardees
                </h2>
              </div>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2023</h3>
                    <a href={BrochureAward} download>
                      See profiles of awardees
                    </a>
                    <ul className="mt-3 ps-4">
                      <li>BEST INDIVIDUAL: DR. VIMAL DENGLA</li>
                      <li>BEST ROLE MODEL: KARAN SINGHANIA</li>
                      <li>
                        BEST INSTITUTION: XRCVC, ST. XAVIERS COLLEGE, MUMBAI
                      </li>
                      <li>BEST CSR IMPACT: GAIL (INDIA) LTD.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2022</h3>
                    <a href={Awardees2022pdf} download>
                      See profiles of awardees
                    </a>
                    <ul className="mt-3 ps-4">
                      <li>BEST INDIVIDUAL: K.C. PANDE</li>
                      <li>BEST ROLE MODEL: RAHUL BAJAJ</li>
                      <li>BEST INSTITUTION: SENSE INTERNATIONAL INDIA</li>
                      <li>BEST CSR IMPACT: UNION BANK OF INDIA</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2021</h3>
                    <a href={Awardees2021pdf} download>
                      See profiles of awardees
                    </a>
                    <ul className="mt-3 ps-4">
                      <li>BEST INDIVIDUAL: DR. BUSHAN PUNANI</li>
                      <li>BEST ROLE MODEL: PRANJAL PATIL</li>
                      <li>BEST INSTITUTION: CBM INDIA TRUST</li>
                      <li>BEST CSR IMPACT: MAKEMYTRIP FOUNDATION</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2020</h3>

                    <ul className="mt-3 ps-4">
                      <li>BEST INDIVIDUAL: PROF. ANIL KUMAR ANEJA</li>
                      <li>BEST ROLE MODEL: AJAY MINOCHA</li>
                      <li>BEST INSTITUTION: LV PRASAD EYE INSTITUTE</li>
                      <li>BEST CSR INITIATIVE: REC FOUNDATION</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2019</h3>

                    <ul className="mt-3 ps-4">
                      <li>BEST INDIVIDUAL: GAUTAM PRAKASH AGARWAL</li>
                      <li>BEST ROLE MODEL: AMAR JAIN</li>
                      <li>BEST INSTITUTION: BLIND PEOPLES ASSOCIATION</li>
                      <li>BEST CSR IMPACT: CONCENTRIX</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2018</h3>

                    <ul className="mt-3 ps-4">
                      <li>BEST INDIVIDUAL: MAHANTESH G.K</li>
                      <li>BEST ROLE MODEL: MOSES CHOWDARI GORREPATI</li>
                      <li>BEST INSTITUTION: SIGHTSAVERS INDIA</li>
                      <li>BEST CSR IMPACT: ESSEL SOCIAL WELFARE FOUNDATION</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="sd-card">
                    <h3>2017</h3>

                    <ul className="mt-3 ps-4">
                      <li>BEST INDIVIDUAL: CHARUDATTA JADHAV</li>
                      <li>BEST ROLE MODEL: TIFFANY BRAR</li>
                      <li>BEST INSTITUTION: SHRI NAVCHETAN ANDHJAN MANDAL</li>
                      <li>BEST CSR IMPACT: TECH MAHINDRA FOUNDATION</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p className="mt-3">
                For more information on this award and earlier recipients please
                contact us.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
