import React, { useEffect, useState } from "react";
import { Col, NavDropdown, Offcanvas, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import {
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineAlignLeft,
  AiOutlineTwitter,
} from "react-icons/ai";
import { CgMenuLeft } from "react-icons/cg";
import "./Navbar.css";
import Logo from "../../assets/images/nabdelhi-logo.png";
// import Logo from "../../assets/images/6A.png";
import { SlEnvolope } from "react-icons/sl";
import { Link } from "react-router-dom";
import Store from "../../Store";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import { BsWhatsapp } from "react-icons/bs";
function SiteNavbar() {
  // const { activeItem, setActiveItem } = Store();
  // const handleItemClick = (item) => {
  //   setActiveItem(item);
  // };
  const [allservicesdropdown, setAllServicesDropdown] = useState([]);
  const [scroll, setScroll] = useState(false);
  window.addEventListener("scroll", () => {
    setScroll(window.scrollY > 50);
  });

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetServicesData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.lengthn != 0) {
          var allTitle = [];
          obj.data.map((services, i) => {
            if (
              allTitle.indexOf(services.fld_title) === -1 &&
              services.fld_status === "Active"
            ) {
              allTitle.push(services);
            }
          });
          setAllServicesDropdown(allTitle);
          Notiflix.Loading.remove();
        } else {
          setAllServicesDropdown([]);
        }
      });
    });
  }, []);

  return (
    <>
      <div
        className={
          scroll ? "sticky-top shadow-sm nav-container" : "nav-container"
        }
      >
        <Navbar expand="lg">
          <Container fluid className="px-lg-3">
            <Navbar.Brand
              href="/"
              className="mx-auto mx-lg-0"
            >
              <img src={Logo} className="logo" alt="logo of NAB Delhi. The letters N A B are written inside a triangle. Below it ‘Serve the Blind’, ‘Tamso ma jyotirgamaya’ and ‘nabdelhi.in’ are written" />
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand`}
              className="me-auto"
            >
              <CgMenuLeft />
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand`}
              aria-labelledby={`offcanvasNavbarLabel-expand`}
              placement="start"
            >
              <Offcanvas.Header
                className="ms-auto"
                closeButton
              ></Offcanvas.Header>
              <Offcanvas.Body className="ms-lg-auto nav-top-links mb-lg-2 order-2 order-lg-1 d-none d-lg-flex align-items-baseline">
                {/* <NavLink href="mailto:info@nabdelhi.in" aria-label=" Email Id" className="nav-link"><SlEnvolope /> <span>	info@nabdelhi.in</span>
                </NavLink>
                <NavLink href="tel:+918826261166" aria-label=" Contact Number" className="nav-link"><IoIosCall /> <span> +91 88262 61166 </span></NavLink> */}
                <Nav.Link
                  className="nav-link"
                  href="/contact-us"
                  aria-label=" Contact Us "
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link className="nav-link nav-link-btn default-btn mb-3"
                  href="/donate" aria-label="Donate now "
                >
                  Donate Now
                </Nav.Link>
              </Offcanvas.Body>
              <Offcanvas.Body className=" order-1 order-lg-2">
                <Nav className="justify-content-end flex-grow-1">
                  <a
                    className="nav-link"
                    // onClick={() => {
                    //   handleItemClick('')
                    // }}
                    href="/"
                    aria-label=" Home "
                  >
                    Home
                  </a>

                  <NavDropdown
                    title="About Us"
                    id="navbarScrollingDropdown"
                    aria-label=" About Drop Down "
                    renderMenuOnMount={true}
                  >
                    <NavDropdown.Item
                      aria-label=" About the organisation "
                      // onClick={() => {
                      //   handleItemClick('/about-organisation')
                      // }}
                      href="/about-organisation"
                    >
                      About the organisation
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/history-achievements"
                      aria-label=" History & Achievements "
                    >
                      History & Achievements
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/our-team"
                      aria-label=" Our Team "
                    >
                      Our Team
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/governing-council"
                      aria-label=" Governing Council "
                    >
                      Governing Council
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/downloads"
                      aria-label=" Statutory documents and Annual Reports "
                    >
                      Downloads
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title="Services"
                    id="navbarScrollingDropdown"
                    aria-label=" Service Drop Down "
                    renderMenuOnMount={true}
                  >
                    {allservicesdropdown.map((servicesoption, i) => {
                      return (
                        <NavDropdown.Item
                          href={`/service/${servicesoption.fld_title.replace(
                            / /g,
                            "-"
                          ).toLowerCase()}/${servicesoption.fld_id}`}
                          aria-label={servicesoption.fld_title}
                        >
                          {servicesoption.fld_title}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  {/* <NavDropdown
                    title="Projects"
                    id="navbarScrollingDropdown"
                    aria-label=" Projects Drop Down "
                    renderMenuOnMount={true}
                  >
                    <NavDropdown.Item
                      href="/ongoing-projects"
                      aria-label=" Ongoing Projects "
                    >
                      Ongoing Projects
                    </NavDropdown.Item>
                    <NavDropdown.Item 
                      href="/completed-projects"
                      aria-label="Completed Projects "
                    >
                      Completed Projects
                    </NavDropdown.Item>
                  </NavDropdown> */}
                  <Nav.Link
                    className="nav-link"
                    href="/ongoing-projects"
                    aria-label="Projects"
                  >
                    Projects
                  </Nav.Link>
                  <NavDropdown
                    title="Awards & Scholarships"
                    id="navbarScrollingDropdown"
                    aria-label=" Awards & Scholarships "
                    renderMenuOnMount={true}

                  >
                    <NavDropdown.Item
                      href="/sarojini-trilok-nath-national-award"
                      aria-label="Sarojini Trilok Nath National Award"
                    >
                      Sarojini Trilok Nath National Award
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item
                      href="/divyangjan-swabhimaan-sammaan-award"
                      aria-label="Divyangjan Swabhimaan Sammaan Award"
                    >
                      Divyangjan Swabhimaan Sammaan Award
                    </NavDropdown.Item> */}
                    <NavDropdown.Item
                      href="/award-for-excellence"
                      aria-label="Award for Excellence"
                    >
                      Award for Excellence
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/sudha-verma-young-achiever-award"
                      aria-label="NAB Sudha Verma Young Achiever Award"
                    >
                      NAB Sudha Verma Young Achiever Award
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/sanjay-tandon-memorial-scholarship"
                      aria-label="Sanjay Tandon Scholarship"
                    >
                      Sanjay Tandon Scholarship
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      href="/lakshmi-iyer-scholarship"
                      aria-label="Lakshmi Iyer scholarship"
                    >
                      Lakshmi Iyer scholarship
                    </NavDropdown.Item>
                    
                  </NavDropdown>
                  <Nav.Link
                    className="nav-link"
                    href="/impact-stories"
                    aria-label=" Impact Stories "
                  >
                    Impact Stories
                  </Nav.Link>
                  <Nav.Link
                    className="nav-link"
                    href="/alumni"
                    aria-label=" Alumni "
                  >
                    Alumni
                  </Nav.Link>
                  <Nav.Link
                    className="nav-link d-block d-lg-none"
                    href="/contact-us"
                    aria-label=" Contact Us "
                  >
                    Contact Us
                  </Nav.Link>
                  <div className="d-flex mt-5 pt-5 flex-row nav-top-links flex-wrap nav-sm-icon d-block d-lg-none mb-3">
                    <div className="d-block w-100 mb-2">
                      <a
                        href="mailto:info@nabdelhi.in"
                        className="nav-link mb-3"
                        aria-label=" Email Id"
                      >
                        <SlEnvolope /> <span> info@nabdelhi.in</span>
                      </a>
                      <a
                        href="tel:+918826261166"
                        className="nav-link"
                        aria-label="Contact Number"
                      >
                        <IoIosCall /> <span> +91 88262 61166 </span>
                      </a>
                    </div>
                    <a href="https://wa.me/+918826260010?text=Hello NAB Delhi"  aria-label="Contact us on Whatsapp" target="_blank"><BsWhatsapp /></a>
                    <NavLink href="https://www.facebook.com/NABdelhiOnline" target={"_blank"} aria-label=" Facebook "><FaFacebookF /></NavLink>
                    <NavLink href="https://www.instagram.com/nab.delhi/" target={"_blank"} aria-label=" Instagram "><AiFillInstagram /></NavLink>
                    <NavLink href="https://www.youtube.com/NABDelhiOnline" target={"_blank"} aria-label=" Youtube "><AiFillYoutube /></NavLink>
                    <NavLink href="https://twitter.com/nabdelhionline" target={"_blank"} aria-label=" Twitter "><AiOutlineTwitter /></NavLink>
                    <NavLink className="pe-lg-0" href="https://www.linkedin.com/company/nabdelhi/" target={"_blank"} aria-label=" Linkedin "><FaLinkedinIn /></NavLink>
                    <Nav.Link className="nav-link nav-link-btn default-btn d-block d-lg-none mb-3"
                      href="/donate" aria-label="Donate now "
                    >
                      Donate Now
                    </Nav.Link>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default SiteNavbar;
