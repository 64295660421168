import React, { useState, useEffect } from "react";
import "./Newsletter.css";
import Navbar from "../../components/Navbar/Navbar";
import { BsFileEarmarkPdf } from "react-icons/bs";
import GetApiCall from "../../GETAPI";
import Notiflix from "notiflix";

export default function Newsletter() {
  const [allNewsLetterData, setAllNewsLetterData] = useState([]);

  useEffect(() => {
    Notiflix.Loading.dots();
    GetApiCall.getRequest("GetNewsLetterData").then((resultdes) => {
      resultdes.json().then((obj) => {
        if (obj.data.length != 0) {
          var activeobjdata = [];
          obj.data.map((objdata, i) => {
            if ((objdata.fld_status = "Active")) {
              activeobjdata.push(objdata);
            }
          });
          setAllNewsLetterData(activeobjdata);
          Notiflix.Loading.remove();
        } else {
          setAllNewsLetterData([]);
          Notiflix.Loading.remove();
        }
      });
    });
  }, []);

  return (
    <>
      <section
        id="newsletter-inner-section"
        className="newsletter-inner-section inner-banner"
      >
        <Navbar />
        <div className="page-title-area">
          <div className="container-fluid pe-4 h-100 d-flex justify-content-end align-items-end">
            <div className="page-title-content">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>About Us</li>
                <li>Newsletter</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container pt-5 pb-3 about-organisation">
          <div className="row">
            <div className="col-12">
              <div className="main-heading text-center pb-1">
                <h1 className="main-title wow animate__animated animate__fadeInUp">
                  Newsletter
                </h1>
                <p className="main-text wow animate__animated animate__fadeInUp">
                  Get the Latest Updates: Download our Newsletter
                </p>
              </div>
            </div>

            {allNewsLetterData.map((newsletter, i) => {
              return (
                <div className="col-md-6 mb-5">
                  <div className="download-card">
                    <a href={newsletter.fld_pdf} target="_blank">
                      <span>{newsletter.fld_name}</span>
                      <BsFileEarmarkPdf />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
